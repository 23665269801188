<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("teachersList.title") }}
          </template>
          <template v-slot:toolbar>
            <b-button v-b-modal.modal-1>{{ $t("common.new") }}</b-button>
            <b-button @click="exportTeachers">Экспорт</b-button>
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th>#</th>
                <th class="text-left">
                  {{ $t("teachersList.inn") }}
                </th>
                <th class="text-left">
                  {{ $t("teachersList.firstName") }}
                </th>
                <th class="text-left">{{ $t("teachersList.name") }}</th>
                <th class="text-left">
                  {{ $t("teachersList.parentName") }}
                </th>
                <th class="text-left">
                  {{ $t("teachersList.contactPhone") }}
                </th>
                <th class="text-left">{{ $t("teachersList.userName") }}</th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, k) in teachers" :key="item.id" :class="isArchive(item)">
                <td>{{ k + 1 }}</td>
                <td>{{ item.inn }}</td>
                <td>{{ item.firstName }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.parentName }}</td>
                <td>{{ item.contactPhone }}</td>
                <td>{{ item.userName }}</td>              
                <td>
                  <b-button
                      class="btn btn-icon btn-success"
                      type="submit"
                      @click="editTeacher(item)"
                      variant="primary"
                      :title="$t('common.edit')"
                  >
                    <i class="flaticon2-edit"></i>
                  </b-button>
                  <router-link :to="{ name: 'teacherInfo', params: { id: item.id }}" class="btn btn-icon btn-success">
                    <i class="flaticon2-arrow"></i>
                  </router-link>
                  <b-button
                      class="btn btn-icon btn-success"
                      v-if="item.isDeletable == true && item.role == 'ROLE_TEACHER'"
                      @click="deleteTeacher(item)"
                      variant="danger"
                      style="margin-left: 10px"
                  >
                    <i class="flaticon2-trash"></i>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
            <div>
              <b-modal id="modal-1" ref="teacher-modal">
                <template v-slot:modal-title>
                  {{ $t("common.new") }}
                </template>
                <b-form v-if="show">
                  <b-form-group id="input-group-1" label-for="input-1">
                    <template v-slot:label>
                      {{ $t("teachersList.inn") }}
                    </template>
                    <b-form-input
                        id="input-1"
                        v-model="form.inn"
                        type="email"
                        required
                        :state="teacherModelStates.inn"
                    ></b-form-input>
                    <div class="invalid-feedback">{{teacherModelSaveError.inn}}</div>
                  </b-form-group>
                  <b-form-group id="input-group-1" label-for="input-1">
                    <template v-slot:label>
                      {{ $t("teachersList.firstName") }}
                    </template>
                    <b-form-input
                      id="input-1"
                      v-model="form.firstName"
                      type="email"
                      required
                      :state="teacherModelStates.firstName"
                    ></b-form-input>
                    <div class="invalid-feedback">{{teacherModelSaveError.firstName}}</div>
                  </b-form-group>

                  <b-form-group id="input-group-2" label-for="input-2">
                    <template v-slot:label>
                      {{ $t("teachersList.name") }}
                    </template>
                    <b-form-input
                      id="input-2"
                      v-model="form.name"
                      required
                      :state="teacherModelStates.name"
                    ></b-form-input>
                    <div class="invalid-feedback">{{teacherModelSaveError.name}}</div>
                  </b-form-group>

                  <b-form-group id="input-group-3" label-for="input-3">
                    <template v-slot:label>
                      {{ $t("teachersList.parentName") }}
                    </template>
                    <b-form-input
                      id="input-3"
                      v-model="form.parentName"
                      required
                      :state="teacherModelStates.parentName"
                    ></b-form-input>
                    <div class="invalid-feedback">{{teacherModelSaveError.parentName}}</div>
                  </b-form-group>
                  <b-form-group id="teacherBd-g" label-for="teacherBd">
                    <template v-slot:label>
                      {{ $t("teachersList.birthDate") }}
                    </template>
                    <b-form-datepicker
                        id="teacherBd"
                        v-model="form.birthDate"
                        required
                    ></b-form-datepicker>
                  </b-form-group>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      {{ $t("teachersList.contactPhone") }}
                    </template>
                    <b-form-input
                      id="input-4"
                      v-model="form.contactPhone"
                      required
                      :state="teacherModelStates.contactPhone"
                    ></b-form-input>
                    <div class="invalid-feedback">{{teacherModelSaveError.contactPhone}}</div>
                  </b-form-group>  
                  <b-form-group id="input-group-4" label-for="input-4">
                    <b-form-checkbox
                      v-model="form.archive"
                    >
                      Архив
                    </b-form-checkbox>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onSubmit" variant="primary">{{
                    $t("common.save")
                  }}</b-button>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                    $t("common.cancel")
                  }}</b-button>
                </template>
              </b-modal>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "schoolAdminTeachers",
  components: {
    KTPortlet
  },
  data() {
    return {
      schoolId: null,
      teachers: null,
      dialog: true,
      form: {
        inn: "",
        firstName: "",
        name: "",
        parentName: "",
        contactPhone: "",
        birthDate: "",
        archive: false
      },
      show: true,
      teacherModelStates:{},
      teacherModelSaveError:{},
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      this.form.schoolId = this.$route.params.id;
      this.teacherModelStates = {};
      this.teacherModelSaveError = {};
      ApiService.postSecured("teachers/save", this.form)
        .then(function() {
          $this.$refs["teacher-modal"].hide();
          $this.reloadData();
          $this.cleanModel();
        })
        .catch(({ response }) => {
          if(response.data != null && response.data.errors != null){
            $this.teacherModelSaveError = response.data.errors;

            for (let i in response.data.errors){

              $this.teacherModelStates[i] = false;
            }
          }
        });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["teacher-modal"].hide();
    },
    reloadData() {
      ApiService.querySecured("teachers/list", {
        params: { schoolId: this.$route.params.id }
      })
        .then(({ data }) => {
          this.teachers = data.list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    cleanModel() {
      this.form = {};
    },
    editTeacher(teacher) {
      this.form = teacher;
      this.teacherModelStates = {};
      this.teacherModelSaveError = {};
      
      this.$refs["teacher-modal"].show();
    },
    isArchive(teacher) {
      return teacher.archive === true ? "table-danger" : "";
    },
    exportTeachers(){
      
      ApiService.downloadSecured("teachers/exportToExcel")
          .then(({data}) => {
            const url = URL.createObjectURL(new Blob([data], {
              type: 'application/vnd.ms-excel'
            }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'Преподаватели пароли.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    deleteTeacher(teacher) {
      let $this = this;
      if(confirm("Удалить преподавателя?")){
        ApiService.postSecured("teachers/delete", {id:teacher.id})
            .then(function () {

              $this.reloadData();
            })
            .catch(() => {

            }).finally(function () {

        });
      }
    }
  }
};
</script>
